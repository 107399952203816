<template>
  <div :class="`MessagesChatCardPrice`">
    <div class="MessagesPriceContent">
      <div class="MessagePriceLeft" :title="$locale['credits']">
        <span class="MessagePriceNumber" strong>{{ Go.number_format(message.price) }}</span>
        <iconic class="MessagePriceIcon" name="coins"></iconic>
      </div>
      <div class="MessagePriceRight">
        <a class="span" small v-if="message.isMe">{{ $locale["for_unlock"] }}</a>
        <a class="primary-button" v-else @click="confirmUnlock">{{ $locale["unlock"] }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["message", "confirmUnlock"],
};
</script>

<style lang="scss">
.MessagesChatCardPrice {
  position: relative;

  .MessagesPriceContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $mpadding * 0.5 0;
  }

  .MessagePriceNumber {
    font-size: 120%;
  }

  .MessagePriceLeft {
    display: flex;
    align-items: center;
    gap: $mpadding/2;
  }

  .MessagePriceIcon {
    opacity: 0.7;
  }
}
</style>
